import { vMount } from '@helpers';
import { log } from 'util';

// import Test from './components/Test.vue';

// vMount('#el', {
//     store,
//     render(h) {
//         return h(Test);
//     }
// });
const html = document.documentElement;
const burger = document.querySelector('.js-burger');
let inputEL = document.querySelectorAll('.js-input'),
    btnCloseModal = document.querySelectorAll('.js-close-modal'),
    lastHeight;

function setVh() {
    lastHeight = window.innerHeight;
    let vh = lastHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
};

window.addEventListener('load', setVh);
window.addEventListener('resize', function() {
    let newHeight = window.innerHeight;
    if (!Modernizr.touchevents && lastHeight !== newHeight || Modernizr.touchevents && Math.abs(lastHeight - newHeight) > 120) {
        setVh();
    }
});

if(burger) {
    burger.addEventListener('click', function () {
        if (html.classList.contains('show-menu')) {
            html.classList.remove('show-menu');
            this.classList.remove('is-active');
        } else {
            this.classList.add('is-active');
            html.classList.add('show-menu');
        }
    });
};

function scrollPage() {
    var scrolled = window.pageYOffset || document.documentElement.scrollTop;
    if(scrolled > 100) {
        html.classList.add('is-scroll');
    } else {
        html.classList.remove('is-scroll');
    }
}

window.onscroll = scrollPage;
window.onload = scrollPage;

if(inputEL) {
    for(let i = 0; i < inputEL.length; i++) {
        inputEL[i].addEventListener('input', function() {
            if(inputEL[i].value != '') {
                inputEL[i].classList.add('no-empty');
            } else {
                inputEL[i].classList.remove('no-empty');
            }
        })
    }
}

btnCloseModal.forEach(btn => {
    btn.addEventListener('click', function() {
        let parent = this.closest('.modal'),
            id = parent.getAttribute('id'),
            modal = new Modal(`#${id}`);
        modal.hide();
    });
});
