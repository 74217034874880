const map = document.getElementById('map');
function initMap() {
    let center = {
        lat: 50.378733,
        lng: 30.478690
    };
    let myMap = new google.maps.Map(map, {
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
        zoom: 16,
        center: center
    });

    let path = require(`@src/img/map/marker.png`);
    if (window.staticAsset) {
        path = window.staticAsset(path);
    }

    new google.maps.Marker({
        position: center,
        map: myMap,
        icon: {
            url: path,
            scaledSize: new google.maps.Size(50, 75),
            origin: new google.maps.Point(0,0), 
            anchor: new google.maps.Point(0, 0) 
        } 
      });
};

if (map) {
    initMap();
}