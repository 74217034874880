import Swiper from 'swiper';

const sliderHome = document.querySelector(`.js-slider-home`);
new Swiper(sliderHome, {
    direction: 'horizontal',
    spaceBetween: 0,
    navigation: {
        nextEl: '.js-home-next',
        prevEl: '.js-home-prev'
    },
    pagination: {
        el: '.js-pagination',
        clickable: true
    }
});

let slider;

// init sliders in project tabs
let arrSlidersProject = [];

function initProject(parent) {
        let next = parent.parentNode.querySelector('.js-arrow-next');
        let prev = parent.parentNode.querySelector('.js-arrow-prev');
        let pagination = parent.querySelector('.js-pagination');
        const settings = {
            direction: 'horizontal',
            spaceBetween: 0,
            navigation: {
                nextEl: next,
                prevEl: prev
            },
            pagination: {
                el: pagination,
                clickable: true
            }
        }
        slider = new Swiper(parent, settings);
        arrSlidersProject.push(slider);
};

function slidersDestroy() {
    for (let i = 0; i < arrSlidersProject.length; i++) {
        arrSlidersProject[i].destroy();
    }
    arrSlidersProject = [];
}

let tab = document.querySelectorAll('.js-tab');
if (tab) {
    for (let i = 0; i < tab.length; i++) {
        tab[i].addEventListener('shown.bs.tab', function () {
            slidersDestroy();
            initslider();
        });
    }
}

initslider();

function initslider() {
    let active = document.querySelector(`.js-tab-pane.active`);
    if(active) {
        let sliderEl = active.querySelectorAll('.js-sliders-project');
        for (let j = 0; j < sliderEl.length; j++)  {
            initProject(sliderEl[j]);
        }
    } 

}

// init sliders projects
let arrProjectsSliders = [];
const projectsSlider = document.querySelectorAll(`.js-slider-projects`);

function initProjects() {
    for (let i = 0; i < projectsSlider.length; i++) {
        let next = projectsSlider[i].parentNode.querySelector('.js-projects-next');
        let prev = projectsSlider[i].parentNode.querySelector('.js-projects-prev');
        const settings = {
            direction: 'horizontal',
            spaceBetween: 40,
            slidesPerView: 3.3,
            lazy: true,
            breakpoints: {
                576: {
                    slidesPerView: 1.3
                },
                992: {
                    slidesPerView: 2.3,
                    spaceBetween: 20
                },
                1200: {
                    slidesPerView: 3.3,
                    spaceBetween: 20
                }
            },
            navigation: {
                nextEl: next,
                prevEl: prev
            }
        }
        slider = new Swiper(projectsSlider[i], settings);
        arrProjectsSliders.push(slider);
    };
}

initProjects();