let btnPlay = document.querySelectorAll('.js-btn-play');
for(let i = 0; i < btnPlay.length; i++ ) {
    btnPlay[i].addEventListener('click', function() {
        this.classList.add('init-player');
        let id = btnPlay[i].getAttribute('data-id');
        let playerEl = this.parentNode.querySelector('.js-player');
        loadPlayer(id, playerEl);
    })
};

function loadPlayer(id, playerEl) { 
    if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {

        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubePlayerAPIReady = function() {
            onYouTubePlayer(id, playerEl);
        };
    } else {
        onYouTubePlayer(id, playerEl);
    }
}
let player;
let playerArr = [];
function onYouTubePlayer(id, playerEl) {
    player = new YT.Player(playerEl, {
        height: '490',
        width: '880',
        videoId: id,
        playerVars: {
            controls: 1
        },
        events: {
            'onStateChange': onPlayerStateChange,
            'onReady' : onPlayerReady 
        }
    });
    playerArr.push(player);
};

let done = false;
function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING && !done) {
        done = true;
    } else if (event.data == YT.PlayerState.ENDED) {
        location.reload();
    }
}

function stopVideo() {
    player.stopVideo();
}

function onPlayerReady(event) {
    event.target.playVideo();
}

let tab = document.querySelectorAll('.js-tab');
if (tab) {
    for (let i = 0; i < tab.length; i++) {
        tab[i].addEventListener('shown.bs.tab', function () {
            for (let j = 0; j < playerArr.length; j++) {
                playerArr[j].stopVideo()
            }
        });
    }
}