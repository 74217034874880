import gsap from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const $logo = document.querySelector('.js-logo-scale');
const $nav = document.querySelector('.js-skew-nav');

ScrollTrigger.matchMedia({
    "(min-width: 1199.98px)": function() {
        gsap.to($logo, {
            scrollTrigger: {
                trigger: $logo,
                start: "top top",
                end: "bottom top",
                scrub: 1,
            },
            width: 95
        });
        gsap.to($nav, {
            scrollTrigger: {
                trigger: $logo,
                start: "top top",
                end: "bottom top",
                scrub: 1,
            },
            x: -35
        });
    },
    "(max-width: 1199.98px)": function() {
        gsap.set($logo, {clearProps: true});
        gsap.set($nav, {clearProps: true});
    }
    
});
